import * as React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../layouts/default";
import Landing from "../components/Landing";
import Text from "../components/Text";
import TextWrapper from "../components/Layouting/TextWrapper";
import Title from "../components/Title";

interface SeitenQuery {
  data: {
    cms: {
      entry: {
        title: string;
        bild: [
          {
            url: string;
          }
        ];
        beschreibung: string;
      };
    };
  };
}

export const SeitenQuery = graphql`
  query ($uri: String!) {
    cms {
      entry(uri: [$uri]) {
        title
        ... on CMS_main_seite_Entry {
          titelFarbe
          text
          bild {
            url
          }
          beschreibung
        }
      }
    }
  }
`;

const Seite = ({ data }) => (
  <DefaultLayout
    beschreibung={data.cms.entry.beschreibung}
    titel={data.cms.entry.title}
  >
    {data.cms.entry.bild ? (
      <Landing
        noDownload
        image={data.cms.entry.bild[0].url}
        text={data.cms.entry.title}
        color={data.cms.entry.titelFarbe}
      />
    ) : (
      ""
    )}

    <Title text={data.cms.entry.title} mobile />
    <TextWrapper>
      <Text text={data.cms.entry.text} marginBottom />
    </TextWrapper>
  </DefaultLayout>
);

export default Seite;
